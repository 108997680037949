@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

:root {
  --color-yellow: #ec9909;
  --color-purple: #7731d8;
  --color-dark-purple: #612dae;
  --color-teal: #00a3a8;
  --color-gradient: linear-gradient(33deg, #ec9909 30%, #ffebd6 60%);
  --color-pink: #ff738e;
  --color-light-yellow: #ffd93b;
}

body, html {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--color-dark-purple);
  color: white;
  height: 100%;
  overflow: hidden;
}

#root, .App {
  height: 100%;
}

.page-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 100%;
  width: 100%;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  text-decoration: none;
}

.square:hover {
  opacity: 0.9;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-yellow);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  width: 20%;
  color: white;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.modal input {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
}

.modal button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: var(--color-dark-purple);
  color: white;
  border: none;
  cursor: pointer;
}

.transition-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark-purple);
}

.transition-shape {
  width: 100px;
  height: 100px;
  border: 2px solid var(--color-yellow);
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.triangle { clip-path: polygon(50% 0%, 0% 100%, 100% 100%); }
.hexagon { clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); }
.octagon { clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); }

.dashboard-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 20px;
  text-align: center;
}

.dashboard-square h2 {
  margin-bottom: 10px;
}

.dashboard-square .score {
  font-size: 48px;
  font-weight: bold;
  margin: 10px 0;
}

.dashboard-square ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.dashboard-square li {
  margin-bottom: 5px;
}

.download-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    color: white; /* This will make the SVG icon white */
  }

.error-message {
  color: var(--color-pink);
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.analysis-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-dark-purple);
}

.analysis-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-dark-purple);
  background-color: var(--color-yellow);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.analysis-button:hover {
  background-color: var(--color-light-yellow);
}

@keyframes fadeOut {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .transition-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-dark-purple);
    z-index: 1000;
  }